<template>
  <transition name="modal">
    <div class="modal-mask" >
      <div v-if="getModalInfo" class="modal-container" @click.stop>
        <div style=" position: absolute; right: 20px;top: 20px;">
          <img @click="hide()" style=" cursor:pointer;width: 24px; height: 24px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>
        <div style="clear: both"></div>

        <div style=" background: white;margin:0 auto; width: 924px; ">
          <div>
            <p class="header">İlanda Görünecek Portfolyo <span>İçeriklerini Seç</span></p>
            <div class="featured-container" :style="drag2 ? 'border-color:#fd4056;background-color: rgba(191, 200, 210, 0.2);' : ''">

                <draggable v-model="selectedPortfolios" forceFallback="true"  v-bind="dragOptions" @start="drag = true"
                           @end="onEnd"  @add="added">
                  <transition-group class="first-trans-group" :name="!drag ? 'flip-list' : null">
                    <div class="port-img-area" v-for="(port, index) in selectedPortfolios.slice(0,4)" :key="'sel'+port.uuid">
                      <div @click="removePortfolio(index, port)" class="delete-area">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="delete-icon">
                      </div>
                      <img class="port-img" :src="port.imageURLSmall">
                    </div>
                  </transition-group>
                </draggable>

            </div>


            <div class="help-area">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              <p class="help-text3">Aşağıda mevcut porfolyonnda yer alan içerikleri yukarıdaki alana sürükleyebilir ve ilanında görünen ilk 4 portfolyo içeriğinin hangileri olacağına karar verebilirsin. Dilersen sıralamasını da kendi aralarında değiştirebilirsin.</p>
            </div>
            <p class="current-title">Bu kategorideki <span>Portfolyon</span></p>
          </div>

          <div class="current-portfolios-container">
            <div class="current-portfolios" v-if="pageLoaded">
              <draggable v-model="nonSelectedPortfolios" @start="onStart2"  forceFallback="true"
                         @end="onEnd2()" ghostClass="ghost"  group="portfolio" style="display: flex; flex-wrap: wrap; ">
                <transition-group style="display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap; width:942px; " :name="!drag2 ? 'flip-list' : null">
                  <div class="current-portfolio" v-for="port in nonSelectedPortfolios" :key="'nonsel'+port.uuid">
                    <img class="current-portfolio-img" :src="port.imageURLSmall">
                  </div>
                </transition-group>
              </draggable>


            </div>
            <loader v-else></loader>
          </div>

        </div>

      </div>
      <div class="modal-footer" style="position: fixed;">
        <div class="modal-footer-inner">
          <button @click="updateGigPortfolios" :disabled="buttonLoading" class="cuper-green-button" style="width: 350px;">Portfolyo İçeriklerini Güncelle</button>
        </div>


      </div>
    </div>
  </transition>
</template>

<script>
  import draggable from 'vuedraggable';

  export default {
    name: "src-pages-modals-editGigFeaturedPortfolio-v1",
    components: {
      draggable,
    },

    data() {
      return {
        drag: false,
        drag2:false,
        gig_id:null,
        gig_uuid:null,
        selectedPortfolios:[],
        nonSelectedPortfolios:[],
        pageLoaded:false,
        buttonLoading:false
      }
    },

    methods: {

      onStart2(){
        this.drag2 = true
        console.log('start');
      },

      updateGigPortfolios() {
        this.buttonLoading = true;

        let selectedPortfolioIDs = this.selectedPortfolios.map(function (portfolio) {
          return portfolio.uuid;
        });

        this.api.seller.updateGigPortfolios(this.gig_uuid, selectedPortfolioIDs, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.EventBus.$emit('updateGigPortfolios', this.selectedPortfolios);
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          });
      },


      removePortfolio(i, port){
        this.selectedPortfolios.splice(i,1);
        this.nonSelectedPortfolios.unshift(port);
      },


      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      onEnd(){
        this.drag = false;

        if(this.selectedPortfolios.length > 4){
          let lastPortfolio = this.selectedPortfolios[4];
          this.selectedPortfolios.splice(4,1);
          this.nonSelectedPortfolios.unshift(lastPortfolio);
        }
      },

      added(){


      },
      onEnd2(){
        this.drag2 = false;

        if(this.selectedPortfolios.length > 4){
          let lastPortfolio = this.selectedPortfolios[4];
          this.selectedPortfolios.splice(4,1);
          this.nonSelectedPortfolios.unshift(lastPortfolio);
        }
      },

      getPortfolios(uuid) {

        this.api.seller.retrieveGigPortfolios(uuid)
          .then(({data}) => {
            let result = data;
            if (result.success) {

              let nonSelecteds = [];
              result.data.non_selected_portfolios.forEach(p => {
                let portfolio = {
                  uuid:p.uuid,
                  fileType:p.file_type,
                  imageURL:p.image_url,
                  imageURLSmall:p.image_url_small,
                  videoURL:p.video_url,
                  itemCount:1,
                  itemCountText: 'Detail',
                  no_image: p.file_type === 'image' ? false : true
                };
                nonSelecteds.push(portfolio);
              });

              this.nonSelectedPortfolios = nonSelecteds;
             // this.selectedPortfolios = this.selectedPortfolios.concat(result.data.selected_portfolios)
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.pageLoaded = true;
          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },


    },


    computed: {

      computedFirstTransGroupWith: function(){
        let w = 177;
        let length = + this.selectedPortfolios.length;
        if(length > 4){
          length = 4;
        }


       return ((length*(w+48)) + 200) + 'px';
      },
      dragOptions() {
        return {
          forceFallback: true,
          animation: 200,
          group: "portfolio",
          disabled: false,
          ghostClass: "ghost",
        };
      },

      getModalInfo() {
        if(!this.gig_id){
          let data = this.$store.state.activeModal.info;
          this.selectedPortfolios  = data.selectedPortfolios;
          this.gig_id = data.gig_id
          this.gig_uuid = data.gig_uuid;
          this.getPortfolios(this.gig_uuid);

        }
        return true;
      },
    },


    created() {


    },

    beforeDestroy() {

    }
  }

</script>

<style scoped lang="scss">


  .first-trans-group{
    display: flex; align-items: center; justify-content: flex-start;overflow:hidden; width: 924px; height: 144px;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.6;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    height: 80px;
    width: 170px;
    display: block;
    margin-left: 44px;
    z-index: 2;
    img{
      width: 170px;
      height: 96px;
    }
  }

  .current-title{
    margin-top: 14px;
    font-size: 26px;
    font-weight: normal;
    color: #2d3640;
    span{
      font-weight: bold;
    }
  }


  .current-portfolios-container{
    cursor: move;
    overflow-y: scroll;  margin-top:20px;
    height: calc(100vh - 427px);
    max-height: 673px;
  }
  .current-portfolios{
    display: flex;
    margin-left: -18px;
    flex-wrap: wrap;
    overflow-y: scroll;
    .current-portfolio{
      margin-left: 16px;
      margin-bottom: 20px;
      width: 170px;
      height: 96px;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
    }
    .current-portfolio-img{
      width: 170px;
      height: 96px;
    }
  }

  .modal-container{
    display: block;
  width: 1000px!important;background: white; padding: 0!important; position: relative;
    height: calc(100vh - 80px);
    max-height: 1000px;
  }
  .modal-footer{

    width: 100%;
    height: 80px;
    .modal-footer-inner{
      border-top: 1px solid #bfc8d2;width: 1000px;
      margin: 0 auto; text-align: center;background: white;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .header{
    padding-top: 20px;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
    color: #2d3640;
    span{
      font-weight: 600;
    }
  }

  .featured-container{
    width: 924px;
    height: 140px;
    margin-top: 20px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);
    display: flex;
    align-items: center;

    .port-img-area{
      cursor: move;
      margin-left: 48px;
      width: 170px;
      height: 95px;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      background-color: #ffffff;
      position: relative;

      .delete-area{
        position: absolute;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        background-color: #ffffff;
        cursor: pointer;
        top:8px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          opacity: 0.8;
        }
        .delete-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #2d3640;
          }

        }
      }
      .port-img{
        width: 170px;
        height: auto;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      }
    }

  }

  .help-area{

    margin-top: 18px;
    display: flex;
    align-items: flex-start;
    img{

    }
    .help-text3{

      margin-left: 5px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.71;
      color: #5e6b79;
    }
  }
</style>
